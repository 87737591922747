import { FillProperty } from 'csstype'
import React from 'react'
import { colors } from '../../../__styling/settings/colors'

export interface PerthNowCanningLogoProps {
    className?: string
    width?: number
    height?: number
    fill?: FillProperty
    title?: string
}

const PerthNowCanningLogo: React.FC<PerthNowCanningLogoProps> = ({
    className,
    title,
    width = 130,
    height = 40,
    fill = colors.black,
}) => {
    return (
        <svg
            width={width}
            height={height}
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 132 60"
            preserveAspectRatio="xMinYMid meet"
        >
            <title>{title || 'Perth Now Canning'}</title>
            <path
                d="M12.3047 52.3593C15.6104 52.3593 17.7775 51.7716 19.4671 51.0002V45.8947C18.1816 46.4089 16.5287 46.9599 13.9576 46.9599C9.25607 46.9599 7.6032 44.3153 7.6032 39.4301V38.2915C7.6032 33.4064 9.91722 31.4229 14.031 31.4229C16.2349 31.4229 17.7041 31.7535 19.2467 32.3044V27.1254C17.8143 26.4276 15.7574 26.0235 12.5985 26.0235C5.58303 26.0235 0 29.5129 0 38.8057V39.6873C0 48.3189 4.5913 52.3593 12.3047 52.3593Z"
                fill="black"
            />
            <path
                d="M26.8195 52.2858C29.2437 52.2858 30.9701 51.5879 31.7781 50.4125H31.9985L32.4393 51.9185H37.8019V37.9977C37.8019 33.59 34.8268 32.0106 30.0151 32.0106C26.9665 32.0106 24.3953 32.3779 22.3751 33.1492V37.41C23.7342 37.0794 25.6074 36.8223 27.5541 36.8223C30.3824 36.8223 31.3741 37.263 31.3741 39.32V40.1648L27.8113 40.6422C23.5505 41.2299 20.7223 42.6257 20.7223 46.7395C20.7223 50.486 23.2199 52.2858 26.8195 52.2858ZM29.0234 48.2087C27.8113 48.2087 26.893 47.6945 26.893 46.0784C26.893 44.6091 27.5541 43.9847 29.0968 43.8745L31.3741 43.6541V47.4374C30.9333 47.8781 30.0151 48.2087 29.0234 48.2087Z"
                fill="black"
            />
            <path
                d="M40.6112 51.9185H47.0391V37.7038C47.8471 37.3732 49.0592 37.0427 50.0877 37.0427C51.8875 37.0427 52.5486 37.5936 52.5486 39.1363V51.9185H58.9765V37.3732C58.9765 33.5165 56.8461 32.0106 53.2098 32.0106C50.7488 32.0106 48.6919 32.7452 47.0023 34.1042H46.8187V32.3779H40.6112V51.9185Z"
                fill="black"
            />
            <path
                d="M61.77 51.9185H68.1978V37.7038C69.0059 37.3732 70.218 37.0427 71.2464 37.0427C73.0462 37.0427 73.7074 37.5936 73.7074 39.1363V51.9185H80.1352V37.3732C80.1352 33.5165 78.0048 32.0106 74.3685 32.0106C71.9076 32.0106 69.8507 32.7452 68.1611 34.1042H67.9774V32.3779H61.77V51.9185Z"
                fill="black"
            />
            <path
                d="M83.0389 51.9185H89.4667V32.3779H83.0389V51.9185ZM83.0022 30.1741H89.5035V25.2522H83.0022V30.1741Z"
                fill="black"
            />
            <path
                d="M92.4657 51.9185H98.8935V37.7038C99.7016 37.3732 100.914 37.0427 101.942 37.0427C103.742 37.0427 104.403 37.5936 104.403 39.1363V51.9185H110.831V37.3732C110.831 33.5165 108.701 32.0106 105.064 32.0106C102.603 32.0106 100.546 32.7452 98.8568 34.1042H98.6731V32.3779H92.4657V51.9185Z"
                fill="black"
            />
            <path
                d="M121.342 59.2646C128.578 59.2646 131.957 56.3629 131.957 52.1021C131.957 48.2087 129.533 46.5926 125.382 46.5926H119.836C118.734 46.5926 118.33 46.262 118.33 45.4907C118.33 45.1234 118.477 44.7928 118.698 44.4989C119.579 44.6826 120.497 44.7561 121.526 44.7561C126.595 44.7561 129.864 42.6992 129.864 38.5486V38.2915C129.864 37.3732 129.643 36.6386 129.202 36.0509H131.7V32.3412H125.125C124.06 32.1208 122.848 32.0106 121.563 32.0106C116.347 32.0106 113.078 34.2144 113.078 38.4384V38.6955C113.078 40.9361 114.033 42.5155 115.686 43.5072C114.253 44.5724 113.261 45.9314 113.261 47.5476C113.261 49.0535 114.18 50.0085 115.392 50.5227V50.7064C113.335 51.3675 111.756 52.5796 111.756 54.5998C111.756 57.3913 114.657 59.2646 121.342 59.2646ZM121.305 55.3344C118.404 55.3344 117.632 54.6365 117.632 53.4979C117.632 52.5796 118.183 52.1021 118.955 51.8083H123.105C125.089 51.8083 125.603 52.0654 125.603 53.2408C125.603 54.4529 124.611 55.3344 121.305 55.3344ZM121.563 41.1565C119.983 41.1565 119.175 40.4953 119.175 38.5853V38.218C119.175 36.3815 119.983 35.6836 121.526 35.6836C123.032 35.6836 123.877 36.1979 123.877 38.2548V38.6221C123.877 40.4953 123.068 41.1565 121.563 41.1565Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M67.3252 6.7494V16.1452C67.3252 19.8732 70.3918 22.8949 74.1714 22.8949H125.071C128.852 22.8949 131.917 19.8732 131.917 16.1452V6.7494C131.917 3.02272 128.852 0 125.071 0H74.1714C70.3918 0 67.3252 3.02272 67.3252 6.7494Z"
                fill="#D42626"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M103.734 4.37622H107.607L110.705 14.2447H110.764L113.654 4.37622H117.527L120.624 14.2447H120.683L123.662 4.37622H127.266L122.411 18.4746H118.866L115.47 8.84081H115.411L112.403 18.4746H108.709L103.734 4.37622"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M96.4497 15.694C99.071 15.694 100.65 13.6683 100.65 11.4652C100.65 9.26236 99.071 7.23568 96.4497 7.23568C93.8284 7.23568 92.2497 9.26236 92.2497 11.4652C92.2497 13.6683 93.8284 15.694 96.4497 15.694M96.45 4.06372C100.769 4.06372 104.225 7.03008 104.225 11.4652C104.225 15.8997 100.769 18.8673 96.45 18.8673C92.1309 18.8673 88.6758 15.8997 88.6758 11.4652C88.6758 7.03008 92.1309 4.06372 96.45 4.06372"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M74.0923 4.41634H77.4883V6.67778H77.548C78.2029 5.26826 79.5434 4.06372 81.9266 4.06372C85.7692 4.06372 87.1396 6.73671 87.1396 9.61467V18.5147H83.5653V11.3774C83.5653 9.82028 83.4461 7.23569 80.9437 7.23569C78.5907 7.23569 77.6669 8.93953 77.6669 10.8192V18.5147H74.0923V4.41634"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M64.5847 11.4468C64.5657 6.8661 62.7738 4.23139 58.3649 4.23139C56.9007 4.23139 55.6062 4.60982 54.3906 5.6124V0.283447H50.9512V18.5972H54.3906V10.8679C54.3906 9.22943 55.2608 7.53491 58.1298 7.53491C59.9598 7.53491 61.134 8.7636 61.134 10.3587C61.1495 10.7149 61.1524 11.0411 61.1524 11.3027V18.5972H64.5912V11.4629L64.5847 11.4468"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.0242 15.1994V15.1911L49.0097 15.1994C47.4429 15.1208 45.9324 13.9723 45.4228 12.7265C45.3076 12.4446 45.2417 11.9788 45.2417 11.4449V7.43773H49.0242V4.54177H45.2417V0.593506H41.8022V10.7454C41.8022 11.2216 41.8495 12.2245 41.94 12.6752C42.6058 16.0028 45.611 18.5133 49.2163 18.5133V15.2082C49.1519 15.2082 49.0886 15.2019 49.0242 15.1994"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.68 4.18715C39.5462 4.171 39.4113 4.15991 39.2716 4.15991L39.2066 4.16403C39.1778 4.16403 39.1497 4.15991 39.1203 4.15991C37.6563 4.15991 36.0534 4.50255 35.145 5.79141V4.50065H31.7065V18.5257H35.145V11.2844L35.1463 11.2353C35.1463 9.28651 36.3095 7.46375 39.1203 7.46375C39.6128 7.46375 40.0826 7.554 40.5189 7.71234V4.29291C40.2443 4.24161 39.9643 4.20931 39.68 4.18715"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.1898 7.46407C24.915 7.46407 26.3838 8.52556 26.9558 10.0136H19.4231C19.9958 8.52556 21.4633 7.46407 23.1898 7.46407M26.5277 13.5837C25.8049 14.6294 24.58 15.3178 23.1894 15.3178C21.3637 15.3178 19.8244 14.1325 19.3306 12.5096H27.047L27.046 12.5121H30.5417C30.5799 12.1362 30.6025 11.7724 30.6025 11.3914C30.6025 7.39725 27.2837 4.15991 23.1885 4.15991C19.0942 4.15991 15.7744 7.39725 15.7744 11.3914C15.7744 15.3856 19.0942 18.6226 23.1885 18.6226C25.6393 18.6226 27.8118 17.4636 29.1616 15.6766L26.5277 13.5837"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.43077 15.3175C5.20691 15.3175 3.40441 13.5597 3.40441 11.3915C3.40441 9.22225 5.20691 7.46346 7.43077 7.46346C9.21225 7.46346 10.7195 8.59334 11.2508 10.1561C11.353 10.5298 11.4064 10.9199 11.4064 11.3161C11.4064 12.1546 11.1684 12.9634 10.7286 13.6401C10.0003 14.6538 8.79607 15.3175 7.43077 15.3175M7.43022 4.15991C3.33595 4.15991 0.0164922 7.39693 0.0164922 11.3914C0.0164922 11.4259 0.0216662 11.4586 0.0216662 11.4921L0 11.5254V22.6339H3.43943V17.4779C4.59259 18.1992 5.9595 18.6223 7.43022 18.6223C11.5251 18.6223 14.8443 15.385 14.8443 11.3914C14.8443 7.39693 11.5251 4.15991 7.43022 4.15991"
                fill="black"
            />
        </svg>
    )
}

export default PerthNowCanningLogo
